

.contact_us {
  width: 100%;
  min-height: 100vh;
  background: #000000;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .desktop__landing__footer {
    //padding-top: 0 !important;
  }

  @media screen and (max-width: 1366px) {
    font-size: 8.5px;
  }
  //align-items: center;

  .nav__wrapper {
    background: #000000;
    margin: 0;
    height: 5.2em;
    padding: 6em;
    max-width: unset;
    width: 100vw;
    position: relative;

    @media (max-width: 470px) {
      padding: 3rem 1rem;
    }

    .nav__logo {

      @media (max-width: 420px) {
        zoom: .8;
      }
    }

    .nav__links {

      @media (max-width: 420px) {
        zoom: .8;
      }
    }
  }

  .final__footer {
    > * {
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 470px) {
      flex-direction: column;
    }

    > * {
      @media (max-width: 470px) {
        margin-bottom: 2em;
      }
    }
  }

  &-body {
    //margin-top: calc(5.2em + 3rem);


    .desktop__overlay {
      position: absolute;
      //background: linear-gradient(
      //                45deg,
      //                rgba(10, 33, 89, 0.7),
      //                rgba(4, 4, 11, 0.7)
      //);
      background: radial-gradient(46.76%36.21%at 50%42.75%,rgba(15,23,40,0)0%,rgba(15,23,40,.08)65%,rgba(0,0,0,1)100%);
      bottom: 0px;
      opacity: 1;
      top: 0px;
      left: 0px;
      right: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }

  &-map {
    &-container {
      position: relative;
      height: 40em;

      iframe {
        height: 40em;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #1e90ff40;
        pointer-events: none;
        z-index: 2;
      }
    }

    &-icon {
      position: absolute;
      bottom: -1.6em;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }
  }

  &-form {
    width: 90%;
    max-width: 110em;
    display: flex;
    padding: 5em 3em;
    margin: 0 auto;

    @media (max-width: 767px) {
      flex-direction: column;
      margin: 5em 3em;
      width: auto;
      padding: 2em 1em;
    }

    &-group {
      flex: 1;
      color: white;
      display: flex;
      flex-direction: column;

      label {
        font-size: 1.5em;
      }

      &.modal {
        border-radius: 1em;
        padding: 3em 2.6em;
        box-sizing: border-box;
        display: block;
        color: #aaa;
        max-width: 45em;
        margin-left: 3em;
        background-color: #121212;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.185), 0 1px 5px 0 rgba(0, 0, 0, 0.05);

        @media (max-width: 767px) {
          margin-left: 0;
          margin-top: 4em;
          max-width: unset;
          width: 100%;
        }
      }

      h2 {
        font-weight: 600;
        font-size: 3.6em;
        //margin-bottom: 2.3em;
      }

      p {
        font-size: 2em;
        line-height: 1.2;
        color: rgba(255, 255, 255, 0.67);
      }

      &-info {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 4em 0;
        flex: 1;

        span {
          font-size: 1.6em;
        }

        .reveal-btn {
          //background-color: #ea0304;
          //border: 1px solid #59abfa;
          //linear-gradient(180deg,#ea0304,#a10e0e)
          color: #eeb947;
          padding: .5em;
          border-radius: 3px;
        }

        > div {
          padding: 1.4em 2em 1.4em 1.4em;
          border-radius: 1em;
          border: 1.3px solid transparent;
          display: flex;
          align-items: center;
          transition: .2s ease-out;
          cursor: pointer;
          margin-bottom: .8em;
          flex-shrink: 0;

          svg {
            margin-right: 1.2em;
            fill: #ee4444;
          }

          &:hover {
            border-color: #ee4444;
            background-color: rgba(234, 3, 4, 0.29);
          }
        }
      }

      &-social {
        display: flex;
        width: 100%;
        //justify-content: center;

        @media screen and (max-width: 500px) {
          justify-content: center;
        }

        a {
          margin-right: 1.5em;
          padding: 1em;
          border-radius: 20em;
          background-color: transparent;

          &:hover {
            background-color: #ee4444;

            svg {
              fill: white;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          svg {
            font-size: 4.5em;
            fill: #ee4444;
            transition: .2s ease-out;
          }
        }
      }
    }
    //display: grid;
    //padding: 40px;
    //grid-gap: 20px;
    //grid-template-columns: 1fr 1fr 1fr;
    //max-width: 900px;
    //margin: 0 auto;

    &-input {
      position: relative;
      margin: 1em 0 2em;

      &.textarea {
        grid-column: 1 / 4;
      }

      &-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1.6em;
        display: flex;
        align-items: center;
        justify-content: center;
        //height: 22px;
        width: 2em;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          right: -1.2em;
          transform: translateY(-50%);
          width: 1px;
          height: 1.3em;
          background-color: #cccccc;
          transition: .2s ease-out;
        }

        svg,
        path {
          fill: var(--color-icon);
          transition: .2s ease-out;
        }
      }

      label {
        color: #2d2d2d;
      }

      input, textarea {
        padding: 1.4em 1.4em 1.4em 3.8em;
        background-color: #eee;
        font-size: 1.6em;
        width: 100%;
        border-radius: 4px;
        color: #181818;
        border: 1px solid #444444FF;
        box-shadow: 0 0 3px 1px #060606FF;

        &:focus {
          border: 1px solid #ea0304;
          box-shadow: 0 0 3px 1px #ea0304;
          outline: none;

          ~ .contact_us-form-input-icon {

            &::before {
              background-color: #ea0304;
              height: 2em;
            }
          }

          ~ .contact_us-form-input-icon svg,
          ~ .contact_us-form-input-icon path {
            fill: #ea0304;
          }
        }
      }

      input {
        height: 45px;
      }
      textarea {
        padding: .8em;
      }
    }

    button {
      width: 100%;
      border-radius: 4px;
      background: linear-gradient(180deg,#ea0304,#a10e0e);
      color: white;
      height: 45px;
      font-size: 15px;
      opacity: 1;
      transition: .2s ease-out;
      //pointer-events: all;
      cursor: pointer;

      * {
        fill: white;
        stroke: white;
      }

      &:disabled {
        opacity: 0.3;
        //pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}


.about__page {
  max-height: 100vh !important;
  width: 100vw;
  overflow: hidden;

  .landing__section {
    min-height: 100vh !important;
    position: relative;
    .landing__wrapper {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      video {
        //position: absolute;
        height: 100%;

        //top: 50%;
        //left: 50%;
        //transform: translate(-50%, -50%);
      }

      .desktop__overlay {
        position: absolute;
        //background: linear-gradient(
        //                45deg,
        //                rgba(10, 33, 89, 0.7),
        //                rgba(4, 4, 11, 0.7)
        //);
        background: radial-gradient(46.76%36.21%at 50%42.75%,rgba(15,23,40,0)0%,rgba(15,23,40,.08)65%,rgba(0,0,0,1)100%);
        bottom: 0px;
        opacity: 1;
        top: 0px;
        left: 0px;
        right: 0;
        width: 100vw;
        height: 100vh;
        margin: 0 auto;
      }

      .about__us__text {
        position: absolute;
        width: 52%;
        top: 42.5%;
        left: 50%;
        transform: translate(-50%, -50%);
        // background: rgba(0, 0, 0, 0.6);
        // backdrop-filter: blur(15px);
        padding: 0px 25px;
        padding-top: 25px;
        border-radius: 15px;

        h1 {
          color: white;
          text-align: center;
          font-size: 25px;
          font-weight: 600;
          margin-bottom: -10px;
        }

        p {
          color: white;
          margin: 25px 0;
          font-size: 18px;
          letter-spacing: 0.5px;
          line-height: 26px;
          text-align: justify;
        }

        @media screen and (max-width: 700px) {
          width: 85%;
          top: 50%;

          p {
            font-size: 15px;
          }
        }

        @media screen and (max-width: 360px) {
          width: 85%;
          top: 50%;

          p {
            font-size: 12px;
            text-align: left;
            margin: 15px 0;
          }
        }
      }
    }
  }

  .desktop__landing__footer {
    position: fixed;
    bottom: 0;
    z-index: 99;
    //background: linear-gradient(to bottom, transparent, rgba(4, 4, 11, 0.7));

    .final__footer {
      display: flex;
      opacity: 1;
      padding: 20px 0;

      @media screen and (max-width: 700px) {
        opacity: 0;
        display: none;
      }
    }
  }
}


.blinking-star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: #fff;
  border-radius: 50%;
  animation: starsBlink 15s ease-in-out infinite;
}

@keyframes starsBlink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.starssection {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  animation: starsAnimateBg 50s linear infinite;
}

.starsspan {
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  animation: starsAnimate 3s linear infinite;

  &:first-child {
    top: 0;
    left: auto;
    animation-delay: 0;
  }

  &::before {
    content: "";
    position: absolute;
    opacity: 35%;
    top: 0;
    width: 150px;
    height: 1px;
    background: linear-gradient(270deg,#fff,transparent);
  }
}

@keyframes starsAnimateBg {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

@keyframes starsAnimate {
  0% {
    transform: rotate(-315deg) translateX(0);
    opacity: 1;
  }

  70% {
    opacity: 1;
  }
  100% {
    transform: rotate(-315deg) translateX(1500px);
    opacity: 0;
  }
}
