// --------------------
// doubt forum

// colors
$base-color: #fafafa;
$light-color: #ffffff;
$icon-color: #949494;
$focus-color: #f2f2f2;
$highlight-color: #891010;
$doubt-tile-width: 625px;
$fluid-width: 580px;

//100 625 100 300 1300

$right-bar-width: 300px;

// --------------------
// newsfeed

// colors
$base-color: #fafafa;
$light-color: #ffffff;
$icon-color: #949494;
$focus-color: #f2f2f2;
$focus-color-dark: #555555;
$highlight-color: #891010;
$fluid-width: 625px;

$google-sign-in: #3c79e6;
$facebook-sign-in: #39559f;
