@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url(../public/assets/fonts/Roboto-Medium.ttf) format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(../public/assets/fonts/Roboto-Regular.ttf) format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url(../public/assets/fonts/Roboto-Light.ttf) format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  src: url(../public/assets/fonts/Roboto-Bold.ttf) format("woff2");
}

@font-face {
  font-family: UtopiaItalic;
  src: url(../public/assets/fonts/Utopia_Italic.ttf);
}

*:focus {
  outline: none;
}

.jodit-react-container blockquote,
.minerva-blockquote-1 {
  padding: 1em 61px;
  margin: 0;
  width: 100%;
  font-size: 1.5vw;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-fon-smoothing: grayscale;
  position: relative;
  font-family: UtopiaItalic;
}

.jodit-react-container blockquote,
.minerva-blockquote-1 {
  position: relative;
}

.jodit-react-container blockquote:after,
.minerva-blockquote-1:after {
  content: "";
  position: absolute;
  left: 3px;
  width: 40px;
  height: 0px;
  /* border-top: 01px solid #434040c7; */
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 1px 1px #b9b9b97d;
}

.minerva-slate .foo {
  position: absolute;
  border-color: #4b5563;
  border-width: 2px;
  width: 25px;
  height: 25px;
  border: none;
}
.minerva-slate .handle-se {
  bottom: -2px;
  right: -2px;
  cursor: se-resize;
  border-bottom: 2px solid #4b5563;
  border-right: 2px solid #4b5563;
}
.minerva-slate .handle-sw {
  bottom: -2px;
  left: -2px;
  cursor: sw-resize;
  border-bottom: 2px solid #4b5563;
  border-left: 2px solid #4b5563;
}
.minerva-slate .handle-nw {
  top: -2px;
  left: -2px;
  cursor: nw-resize;
  border-top: 2px solid #4b5563;
  border-left: 2px solid #4b5563;
}
.minerva-slate .handle-ne {
  top: -2px;
  right: -2px;
  cursor: ne-resize;
  border-top: 2px solid #4b5563;
  border-right: 2px solid #4b5563;
}

.minerva-slate ul {
  list-style: disc;
}

.minerva-slate ol {
  list-style: decimal;
}

.minerva-slate ul,
.minerva-slate ol {
  padding-inline-start: 0.8125rem;
  margin: 0;
}

.minerva-slate ul li,
.minerva-slate ol li {
  margin-inline-start: 0.8125rem;
  padding-inline-start: 0.5rem;
  line-height: 1.6875rem;
}

.minerva-slate ul li:not(:last-child),
.minerva-slate ol li:not(:last-child) {
  margin-bottom: 5px;
}

.breadcrumb-trigger[aria-expanded="true"] .caret-icon {
  transform: rotate(90deg);
}

.breadcrumb-trigger[aria-expanded="true"] {
  background: rgb(128, 22, 21);
  color: white;
}

.slate-grid-container {
  display: grid;
  --mobile-cols: 1;
  --tablet-cols: 2;
  --desktop-cols: 3;
  grid-template-columns: repeat(var(--mobile-cols), minmax(0, 1fr));
}

@media screen and (min-width: 768px) {
  .slate-grid-container {
    grid-template-columns: repeat(var(--tablet-cols), minmax(0, 1fr));
  }
}

@media screen and (min-width: 1024px) {
  .slate-grid-container {
    grid-template-columns: repeat(var(--desktop-cols), minmax(0, 1fr));
  }
}

.slate-line-element > .slate-line-element-tool {
  opacity: 0;
}

.slate-line-element:hover > .slate-line-element-tool {
  opacity: 1;
}

.end-3 {
  right: 10px;
}

@media screen and (max-width: 1600px) {
  .jodit-react-container blockquote,
  .minerva-blockquote-1 {
    font-size: 2vw;
  }
}

@media all and (max-width: 1200px) {
  .jodit-react-container blockquote,
  .minerva-blockquote-1 {
    font-size: 2.5vw;
  }
}

@media all and (max-width: 600px) {
  .jodit-react-container blockquote,
  .minerva-blockquote-1 {
    font-size: 18px;
    width: 100%;
    min-width: 100%;
  }
}

.full_name_input {
  width: 90%;
  height: 45px;
  display: block;
  z-index: 23;
  position: relative;
  margin: 50px auto;
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
  padding: 0 10px;
  background: none;
  color: white;

  &:focus {
    outline: none;
    border: none;
    border-bottom: 2px solid #fff;
    background: none;
  }
}

@media screen and (max-width: 769px) {
  .reservation__details__backdrop {
    padding-top: 0 !important;
    flex-direction: column !important;
    justify-content: space-evenly !important;

    .reservation__details {
      display: flex;

      .reservation__chat__back {
        margin-left: 10px !important;
        margin-top: 3px;
        margin-right: 5px;
      }

      .reservation__name {
        white-space: normal !important;
      }

      .reservation__options {
        .call__btn {
          margin-top: 0 !important;
          margin-right: 5px;
        }

        .vert__icon {
          position: relative !important;
          top: unset !important;
          right: unset !important;
        }
      }
    }

    .reservation__details__sm {
      margin-left: 20px;

      .instructor-details {
        margin-left: 0px;
        margin-top: 0px;
      }
    }
  }
}

.reservation__details__sm {
  display: none;

  @media screen and (max-width: 769px) {
    display: block;

    .instructor-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;
      margin-top: 30px;
      border-top: 2px solid rgba(128, 128, 128, 0.15);

      @media screen and (max-width: 500px) {
        margin-left: -32.5px;
        .instructor-inner {
          .instructor-img {
            width: 38px !important;
            height: 38px !important;

            .image__instructor {
              width: 34px !important;
              height: 34px !important;
            }
          }
        }
      }

      .instructor-inner {
        display: flex;
        align-items: center;

        .instructor-search {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          margin-left: 2px;
        }

        .instructor-img {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(
            to right,
            rgb(243, 184, 57) 0%,
            rgb(190, 65, 7) 50%,
            rgb(243, 184, 57) 100%
          );

          animation: flowingText 4s linear both infinite;
          background-size: 200% auto;
          border-radius: 50%;

          width: 44px;
          height: 44px;
          position: relative;

          .image__instructor {
            width: 40px;
            height: 40px;
            object-fit: cover;
            object-position: top;
            border-radius: 50%;

            &.searching {
              padding: 7.5px;
              border-radius: 0;
              filter: invert(1);
            }
          }

          .image__verified {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: absolute;
            bottom: 0px;
            right: 0px;
          }
        }

        section {
          padding-left: 15px;
          div {
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            h4 {
              color: var(--color-text);
              opacity: 0.75;
              font-weight: 500;
              text-shadow: 0 0 2.5px rgba(128, 128, 128, 0.411);
              white-space: nowrap;
              width: 179px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .star-ratings {
              transform: scale(0.75);
              width: 51%;
            }
          }

          h6 {
            font-size: 12px;
            color: var(--color-text);
            opacity: 0.5;
            font-weight: 500;
            text-transform: capitalize;
            padding-top: 5px;
          }
        }
      }
    }
  }
}

.explore__scholarship__btn.form {
  text-align: center;
  margin-top: 30px;
  color: dodgerblue;
  cursor: pointer;
  font-size: 1.4em;

  &.noCursor {
    cursor: default;
  }
}

.recommended-video-1 {
  margin-bottom: 12.5px;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 6;
  display: flex;

  [data-vimeo-initialized="true"] {
    height: 100%;
    aspect-ratio: 16 / 9;
    //position: absolute;
    top: 0;
    z-index: 2;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  .video-detail {
    overflow: hidden;
    padding: 15px;

    h1 {
      color: var(--color-text);
      font-size: 18px;
      font-weight: 600;
      opacity: 0.9;
      padding-bottom: 15px;
    }

    p {
      color: var(--color-text);
      opacity: 0.65;
      font-size: 16px;
      width: 100%;
    }

    .youtube-player-wrapper {
      margin-top: 0 !important;
    }
  }
}

.pustack__pro__drawer {
  .react-swipeable-view-container {
    transition: all 0.3s ease 0s !important;
  }
}

.session__form__upload-btn {
  &.special {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    input {
      z-index: 4;
    }

    svg,
    svg path {
      fill: rgba(128, 128, 128, 0.33);
    }

    .overlay__svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      z-index: 5;

      &.url,
      &.url path,
      &.url polygon {
        opacity: 0;
        transition: 0.2s ease-out;
        z-index: 3;
        fill: rgba(234, 234, 234, 1);
      }

      .stroke,
      .stroke path {
        stroke: rgba(234, 234, 234, 1);
        fill: none;
        z-index: 34;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.28);
      z-index: 2;
      opacity: 0;
      transition: 0.2s ease-out;
    }

    &.url {
      &:hover {
        .overlay__svg.url,
        .overlay__svg.url path,
        .overlay__svg.url polygon {
          opacity: 1;
        }

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

//.mobile__hero {
//  @media screen and (max-height: 768px) {
//    zoom: 0.89;
//  }
//}

.ask-name-modal {
  width: 90vw;
  max-width: 400px;
  padding: 20px;
  position: absolute;
  background-color: var(--color-primary);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.grant-button {
  background: rgb(30, 184, 215);
  background: radial-gradient(
    circle,
    rgba(30, 184, 215, 1) 15%,
    rgba(83, 103, 250, 1) 100%
  );
  letter-spacing: 1px;
}

.ask-name-input {
  display: block;
  width: 100%;
  border-radius: 0.5rem;
  border-width: 1px;
  //--tw-border-opacity: 1;
  //border-color: rgb(209 213 219 / var(--tw-border-opacity));
  //--tw-bg-opacity: 1;
  //background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  padding: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  //--tw-text-opacity: 1;
  //color: rgb(17 24 39 / var(--tw-text-opacity));

  @apply text-black dark:text-white bg-gray-50 dark:bg-neutral-900 border-gray-200;
}

.mt-3 {
  margin-top: 0.4rem;
}

.mb-4 {
  margin-bottom: 1.2rem;
}

.ask-name-button {
  margin-top: 1rem;
  display: flex;
  height: 2.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.loader-37 {
  border-right: 0.1em solid currentcolor;
  border-radius: 100%;
  flex: 1;
  -webkit-animation: loader-37 800ms linear infinite;
  animation: loader-37 800ms linear infinite;
}
.loader-37:before,
.loader-37:after {
  content: "";
  width: 0.8em;
  height: 0.8em;
  display: block;
  position: absolute;
  top: calc(50% - 0.4em);
  left: calc(50% - 0.4em);
  border-left: 0.08em solid currentcolor;
  border-radius: 100%;
  animation: loader-37 400ms linear infinite reverse;
}
.loader-37:after {
  width: 0.6em;
  height: 0.6em;
  top: calc(50% - 0.3em);
  left: calc(50% - 0.3em);
  border: 0;
  border-right: 0.05em solid currentcolor;
  -webkit-animation: none;
  animation: none;
}

@-webkit-keyframes loader-37 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes loader-37 {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.ongoing-card.dashboard {
  height: 86px;
  border-radius: 12.5px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin: 5px auto;
  border: 2.5px solid rgba(136, 136, 136, 0.15);
  flex-shrink: 0;

  .ongoing-card-bg {
    background: var(--blaze-card-bg);
    background-size: 100%;
    background-position: center;
    height: 86px;
    width: 200px;
    padding: 0;
    border-radius: 12.5px;
    filter: var(--blaze-card-filter);
    position: absolute;
  }

  .card-content {
    display: flex;
    margin-top: 10px;
    position: relative;

    .unread-messages {
      position: absolute;
      right: 10px;
      top: 0px;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: #c71538;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.25s ease both;
      animation-delay: 0.5s;

      h6 {
        font-size: 10px;
      }
    }

    .card-neon-line {
      width: 3px;
      margin-right: 10px;
      margin-left: 10px;
    }

    h1 {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-text);
      white-space: nowrap;
      width: calc(100% - 50px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card-divider {
    background: rgba(128, 128, 128, 0.15);
    min-height: 2px;
    max-height: 2px;
    width: 100%;
  }

  .student-details {
    padding: 5px 7.5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .student-inner {
      display: flex;
      align-items: center;
      overflow: hidden;

      .student-img {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background: linear-gradient(
          to right,
          rgb(243, 184, 57) 0%,
          rgb(190, 65, 7) 50%,
          rgb(243, 184, 57) 100%
        );
        background-size: 200% auto;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        position: relative;

        .image__student {
          width: 28px;
          height: 28px;
          object-fit: cover;
          object-position: top;
          border-radius: 50%;
        }
      }

      section {
        padding-left: 7.5px;
        min-width: 0;
        div {
          display: flex !important;
          justify-content: space-between;
          align-items: center;
          h4 {
            font-size: 12px;
            color: var(--color-text);
            opacity: 0.75;
            font-weight: 500;
            text-shadow: 0 0 2.5px rgba(128, 128, 128, 0.411);
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        h6 {
          font-size: 11px;
          color: var(--color-text);
          opacity: 0.5;
          font-weight: 500;
          padding-top: 2.5px;
          text-transform: capitalize;
        }
      }
    }

    button {
      color: var(--color-text);
      border-radius: 50%;
      background: var(--color-primary) !important;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;

      align-items: center;
      opacity: 0.8;
      box-shadow: 1px 0.5px 1px 1px rgba(128, 128, 128, 0.25);

      svg {
        font-size: 24px;
        margin-left: 2px;
        opacity: 0.6;
        color: var(--color-text);
      }
    }
  }
}

.sessions-heading {
  padding-top: 0px;
  padding-left: 16px;
  padding-bottom: 8px;
  font-size: 14px;
  color: rgb(104, 104, 104);
  font-weight: 500;
  margin-top: 10px;
}

.capitalize {
  text-transform: capitalize;
}

.show-all-btn.dashboard {
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  cursor: pointer;
  font-size: 13px;
  overflow: hidden;
  font-weight: 600;
  position: absolute;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1.5px);
  background: linear-gradient(
    to right,
    transparent 5%,
    var(--color-primary) 65%
  );

  @media screen and (max-width: 500px) {
    width: auto;
    margin-right: 0px;
    background: transparent;
    position: relative;
  }

  .show-all-content {
    display: flex;
    align-items: center;

    h1 {
      opacity: 0.65;
      padding-left: 0;
      color: var(--color-text);
      margin-bottom: 0;

      @media screen and (max-width: 500px) {
        color: #fff;
        background: #079638;
        padding: 0.6em 1.5em;
        border-radius: 20px;
        opacity: 1;
      }
    }
  }
}

.grade-settings-tooltip {
  position: absolute;
  top: 160%;
  right: 14px;
  animation: MoveUpDown 1s cubic-bezier(0.17, 0.42, 0.51, 0.79) infinite;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  padding: 10px;
  background-color: #0096c7;
  color: white;
  border-radius: 7px 0 7px 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  z-index: 23;
  pointer-events: none;

  > svg {
    position: absolute;
    bottom: calc(100% - 12px);
    right: 0px;
    width: 15px;
    height: 30px;

    path {
      fill: #0096c7;
    }
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
}

.reservation_lecture {
  .plyr {
    width: 300px;

    .plyr__controls__item .plyr__menu__container .plyr__control:hover {
      background-color: #00b2ff !important;
    }
  }
}

.chapter__card.chats {
  margin: 0 !important;

  @media screen and (max-width: 500px) {
    height: 92px;
    max-width: 70vw;
  }

  .chapter__thumbnail {
    padding-left: 0.75rem;

    .chapter__thumb__overlay {
      left: 12px;
      background: rgba(0, 0, 0, 0.15);

      svg {
        opacity: 1;
        font-size: 2.5rem;
      }
    }
  }

  .chapter__content {
    padding: 15px 10px;
    overflow: hidden;

    @media only screen and (max-width: 720px) {
      overflow: hidden;
      padding: 10px 5px 10px 0;
    }

    h6 {
      width: 90%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;

      @media only screen and (max-width: 720px) {
        font-size: 12px;
        width: 100%;
        -webkit-line-clamp: 2;
      }
    }

    .chapter__title {
      h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media only screen and (max-width: 720px) {
          font-size: 0.9rem;
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .chapter__content__header .chapter__lectures {
      bottom: 15px;

      @media only screen and (max-width: 720px) {
        bottom: 2px;
      }
    }
  }
}

.comment__content.image.chapter {
  max-width: 90%;
  overflow: hidden;
}

@layer components {
  .star-background {
    //background: rgb(0,0,38);
    //background: linear-gradient(129deg, rgba(0,0,38,1) 0%, rgba(0,0,18,1) 100%);
    @apply bg-[linear-gradient(0deg,_rgba(94,94,196,0.1)_100%,_rgba(10,20,251,0.5)_0%)] dark:bg-[linear-gradient(0deg,_rgba(0,0,38,1)_0%,_rgba(0,0,18,1)_100%)];
  }

  .animate-float {
    animation: animate-floatStars 50s linear infinite;
  }

  .primary__btn {
    cursor: pointer;
    z-index: 999;
    margin-top: 2rem;
    margin-bottom: 0rem;
    border-radius: 7.5px;
    background: linear-gradient(to bottom, #ea0304, #a10e0e);
    color: white;
    font-weight: 600;
    padding: 0.8rem 2rem;
    filter: brightness(0.95) saturate(1);
    transition: all ease 0.2s;
  }
  .primary__btn:hover {
    filter: brightness(1) saturate(1.05);
    // transform: scale(1.01);
    transition: all ease 0.2s;
  }

  .red__heading {
    font-size: 22px !important;
    font-weight: 700;
    animation: flowingText 8s linear both infinite;
    background: linear-gradient(to left, #ff512f 5%, #dd2476 50%, #ff512f 95%);
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
  }
}

.minerva-blockquote {
  padding: 60px 80px 40px;
  position: relative;
}
.minerva-blockquote {
  font-family: UtopiaItalic;
  font-size: 35px;
  font-weight: 700px;
  text-align: center;
}

/*minerva-blockquote p::before {
  content: "\f095"; 
  font-family: FontAwesome;
 display: inline-block;
 padding-right: 6px;
 vertical-align: middle;
font-size: 180px;
}*/

.minerva-blockquote:before {
  position: absolute;
  font-family: "FontAwesome";
  top: 0;

  content: "\f10d";
  font-size: 200px;
  color: rgba(0, 0, 0, 0.1);
}

.minerva-blockquote::after {
  content: "";
  top: 20px;
  left: 50%;
  margin-left: -100px;
  position: absolute;
  border-bottom: 3px solid #bf0024;
  height: 3px;
  width: 200px;
}

@keyframes animate-floatStars {
  0% {
    transform: translate(-20px, 20px) rotate(0deg);
  }
  25% {
    transform: translate(20px, 20px) rotate(15deg);
  }
  50% {
    transform: translate(-20px, -20px) rotate(30deg);
  }
  75% {
    transform: translate(20px, -20px) rotate(15deg);
  }
  100% {
    transform: translate(-20px, 20px) rotate(0deg);
  }
}

.home-page-banner {
  width: 100%;
  max-width: 95vw;
  margin: 0 auto;
  aspect-ratio: 16/5;
  background-image: linear-gradient(
    129deg,
    rgba(94, 94, 196, 0.1) 100%,
    rgba(10, 20, 251, 0.5) 0%
  );
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;

  @media screen and (max-width: 500px) {
    aspect-ratio: 16/7;
  }

  &.dark {
    background: rgb(0, 0, 38);
    background: linear-gradient(
      129deg,
      rgba(0, 0, 38, 1) 0%,
      rgba(0, 0, 18, 1) 100%
    );

    .content-section {
      color: #ffffff;
      min-width: 50%;

      h2 {
        color: #fff;
        -webkit-text-fill-color: #fff;
      }
    }
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    //background: rgba(0,0,0,.2);
    top: 0;
    left: 0;
    z-index: 1;
  }

  .stars-bg {
    position: absolute;
    width: 47%;
    top: 10%;
    -webkit-animation-name: animateRandomFloat;
    -webkit-animation-duration: 45s;
    animation-name: animateRandomFloat;
    animation-duration: 45s;
    animation-iteration-count: infinite;
    z-index: -1;
    opacity: 0.35;

    &-1 {
      left: 0;
    }
    &-2 {
      left: 25%;
    }
    &-3 {
      left: 50%;
    }
    &-4 {
      right: 0;
    }
  }
  @keyframes animateRandomFloat {
    25% {
      transform: rotate(-2deg) scale(0.8) translateY(13px) translateX(16px);
    }
    50% {
      transform: rotate(-8deg) scale(0.9) translateY(12px) translateX(11px);
    }
    75% {
      transform: rotate(3deg) scale(0.8) translateY(16px) translateX(20px);
    }
  }

  .image-section {
    position: absolute;
    margin-right: 3em;
    z-index: 2;
    left: 10%;
    width: 100px;
    height: 100px;

    @media screen and (max-width: 500px) {
      left: -3%;
    }

    svg {
      width: 13em !important;
    }

    img {
      display: block;
      z-index: 2;
      position: absolute;
      top: -41px;
      left: 91px;
      rotate: 45deg;
      scale: 0.3;
      width: 100px;
      height: 100px;

      @media screen and (max-width: 500px) {
        top: -37px;
        left: 73px;
      }
    }
  }

  .content-section {
    color: #181818;
    font-family: Roboto, sans-serif;
    padding-right: 1em;
    z-index: 2;
    width: 55%;
    position: absolute;
    right: 0;

    @media screen and (max-width: 500px) {
      width: 60%;
    }

    h2 p {
      font-family: Roboto, sans-serif;
    }

    p {
      max-width: 15em;
      opacity: 0.55;
      font-size: 1em;
      margin-bottom: 0.1em;

      @media screen and (max-width: 500px) {
        font-size: 1.1em;
        max-width: unset;
      }
    }

    h2 {
      //font-size: 23px;
      font-family: Roboto, sans-serif;
      animation: flowingText 8s linear both infinite;
      background: linear-gradient(
        to left,
        #cd0909 5%,
        #6d1616 50%,
        #cd0909 95%
      );
      background-size: 200% auto;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.7em;
      font-weight: 600;
      margin-bottom: 0.2em;

      @media screen and (max-width: 500px) {
        font-weight: 500;
      }
    }

    .book-session-banner-btn {
      background: linear-gradient(135deg, #cd0909, #6d1616);
      border-radius: 100px;
      padding: 9px 22px;
      margin-top: 10px;
      color: white;
      font-size: 1.2em;
      transform: translateX(-7px);

      svg,
      path {
        fill: white;
        stroke: white;
        color: white;
      }
    }
  }
}

.classroom-player-wrapper {
  height: auto;
  aspect-ratio: 16 / 9;

  .plyr.plyr--full-ui {
    height: auto !important;
    aspect-ratio: 16 / 9 !important;
  }
}

.lecture-search-tile .video-wrapper {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
    cursor: pointer;
  }
}

.guest-user-banner-text {
  @media screen and (max-width: 500px) {
    zoom: 0.8;
  }
}

.guest-bottom-drawer {
  z-index: 1301 !important;

  .MuiDrawer-paperAnchorBottom {
    height: 160px;
    border-radius: 10px 10px 0 0;
    background-image: linear-gradient(
      129deg,
      rgba(0, 0, 38, 1) 0%,
      rgba(0, 0, 18, 1) 100%
    );

    .stars-bg {
      position: absolute;
      width: 47%;
      top: 10%;
      -webkit-animation-name: animateRandomFloat;
      -webkit-animation-duration: 45s;
      animation-name: animateRandomFloat;
      animation-duration: 45s;
      animation-iteration-count: infinite;
      z-index: -1;
      opacity: 0.35;

      &-1 {
        left: 0;
      }
      &-2 {
        left: 25%;
      }
      &-3 {
        left: 50%;
      }
      &-4 {
        right: 0;
      }
    }
    @keyframes animateRandomFloat {
      25% {
        transform: rotate(-2deg) scale(0.8) translateY(13px) translateX(16px);
      }
      50% {
        transform: rotate(-8deg) scale(0.9) translateY(12px) translateX(11px);
      }
      75% {
        transform: rotate(3deg) scale(0.8) translateY(16px) translateX(20px);
      }
    }

    .embla__slide {
      overflow: hidden;
    }

    .stars__background {
      height: 160px;
    }

    .welcome-text {
      z-index: 2323;
      color: white;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 24px;
      position: relative;

      .astronaut {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-56%);
        width: 180px;

        @media screen and (max-width: 500px) {
          width: 200px;
          zoom: 0.8;
        }
      }
    }
  }

  //.MuiPaper-root {
  //  background: transparent !important;
  //}
}

.red-glowing-text {
  font-family: Roboto, sans-serif;
  animation: flowingText 8s linear both infinite;
  //background: linear-gradient(to left, #cd0909 5%, #6d1616 50%, #cd0909 95%);
  background: #cd0909;
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.7em;
  font-weight: 600;
  margin-bottom: 0.2em;
}

.choose-grade-paper {
  height: 70vh;

  .slide__page {
    height: 100%;

    .fourth__page__inner {
      height: 100%;

      .select__classes .class__item {
        background: rgba(130, 130, 130, 0.87);
      }
    }
  }
}

.user__menu__btn {
  &.is_guest {
    padding: 0 10px 0 0 !important;

    @media screen and (max-width: 500px) {
      padding: 0 0 0 0 !important;
    }
  }
}

.guest-mode-user-dp {
  background: var(--search-bg);
  width: 32px;
  border-radius: 200px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;

  img {
    width: 20px;
    position: absolute;
    transform: scale(1.5) translate(4px, 4px);
  }
}

.chapter__card {
  .chapter__thumbnail__wrapper {
    position: relative;

    .chapter__thumb__overlay {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .loader__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.app-browser-prompt {
  width: 100vw;

  .MuiDrawer-paperAnchorBottom {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: transparent;
  }

  &-container {
    background: #181818;
    color: #fff;
    padding: 15px;
  }

  &-header {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.16);
    padding: 8px 0;
    margin-bottom: 5px;

    h2 {
      margin-bottom: 5px;
      text-align: center;
      font-weight: 400;
      font-family: Roboto, sans-serif;
      font-size: 1em;
      opacity: 0.75;
    }
  }

  .support-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;

    span {
      opacity: 0.65;
    }

    &-label {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        margin-right: 9px;
        border-radius: 200px;
      }
    }

    button {
      border: 1px solid rgba(255, 255, 255, 0.64);
      border-radius: 200px;
      padding: 10px 15px;
      width: 110px;
      text-align: center;
      color: var(--color-text);

      &.open-app {
        background: linear-gradient(135deg, #cd0909, #6d1616);
        border-radius: 100px;
        padding: 10px 22px;
        margin-top: 10px;
        color: white;
        //font-size: 1em;
        border: none;
      }
    }
  }
}

.explore_classroom {
  //background-image: linear-gradient(to right, #1FA2FF 0%, #12D8FA  51%, #1FA2FF  100%);
  background-color: #0053a6;
  //margin: 10px;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  //text-transform: uppercase;
  transition: all 0.2s !important;
  background-size: 200% auto;
  color: white;
  //box-shadow: 0 0 20px #eee;
  border-radius: 8px;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #0079f2;
  }

  //.btn-grad:hover {
  //  background-position: right center; /* change the direction of the change here */
  //  color: #fff;
  //  text-decoration: none;
  //}
}

.cwc__card {
  margin: 0;
  display: block;

  .pustack__mark__overlay {
    display: none;
  }

  .subject__thumbnail {
    border: 2px solid rgba(180, 180, 180, 0.2);
    max-height: 145px;
    img.maths__thumbnail {
      object-fit: contain !important;
    }
  }

  .subject__title {
    padding-top: 7.5px;
    color: var(--color-text);
    width: 100%;

    font-size: 16px;
    font-weight: 500;
    opacity: 0.75;
    display: flex;

    svg {
      font-size: 22px;
      margin-right: 7.5px;
      color: var(--color-highlight);
      filter: brightness(1.25);
      opacity: 1;
      vertical-align: -0.2rem;
    }
  }
}

.landing__wrapper {
  position: sticky;
  top: 0;
  left: 0;
}

.space-wars-bg {
  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  canvas {
    background: #0e1525;
  }
}

.main-content {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 30px;

  @media screen and (max-width: 500px) {
    gap: 35px;
  }
}

.main-content-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  //gap: 8px;

  @media screen and (max-width: 500px) {
    zoom: 0.7;
  }

  h2 {
    font-size: 19px;
    line-height: 1.6;
    //font-weight: 500;
    color: white;
  }

  &-subitem {
    //margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex-basis: auto;

    @media screen and (max-width: 500px) {
      display: none;
    }

    span {
      font-size: 28px;
      line-height: 1.6;
      //font-weight: 300;
      color: rgba(255, 255, 255, 0.45);
    }

    .highlight {
      color: white;
      font-weight: 500;
    }
  }
}

.carousel-holder {
  display: flex;
  flex-direction: row;
  width: max-content;
  outline: none;
  flex-shrink: 0;
  gap: 20px;
}

.carousel-imaged {
  &-ul {
    display: flex;
    flex-direction: row;
    flex-basis: auto;
    flex-shrink: 0;
    gap: 20px;
    transform: translateX(-100px);
    align-items: center;
    -webkit-box-align: center;
    height: 328px;

    @media screen and (max-width: 500px) {
      gap: 20px;
      height: 200px;
    }
  }

  &-item {
    width: calc(80vw - 16px);
    max-width: 400px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: #1c2333
      radial-gradient(100% 100% at 0% 0%, #212839 0%, #1c2333 100%);
    box-sizing: content-box;
    border: 1px solid #2b3245;
    box-shadow: 0 4px 8px 0 rgba(2, 2, 3, 0.16);
    transform-origin: bottom;
    border-radius: 16px;
    padding: 16px;
    gap: 16px;
    transition: all 160ms ease-out !important;

    @media screen and (max-width: 500px) {
      //zoom: 0.8;
      gap: 11px;
      width: 220px;
      //background: none;
      //border: none;
      //box-shadow: none;
      border-radius: 10px;
      padding: 10px;
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 8px 16px 0px rgba(2, 2, 3, 0.32);
      border-color: #3c445c;

      @media screen and (max-width: 500px) {
        transform: none;
        box-shadow: 0 4px 8px 0 rgba(2, 2, 3, 0.16);
        border-color: #2b3245;
      }

      .carousel-imaged-item-bottom-hidden {
        max-height: 116px;
        margin-top: 8px;

        @media screen and (max-width: 500px) {
          max-height: 0;
          margin-top: 0;
        }
      }
    }

    //@media screen and (min-width: 500px) {
    //  border-radius: 16px;
    //  padding: 16px;
    //  gap: 16px;
    //}

    &-header {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      color: #fff;
      overflow: hidden;
      //overflow-y: visible;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 500px) {
        font-size: 13px;
        line-height: 16px;
      }

      .maths {
        background: linear-gradient(
          to left,
          #fb8b23 20%,
          #ffdd00 40%,
          #ffdd00 60%,
          #fb8b23 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .physics {
        background: linear-gradient(
          to left,
          #ed82c1 20%,
          #f9c6d7 30%,
          #f9c6d7 60%,
          #ed82c1 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .chemistry {
        background: linear-gradient(
          to left,
          #ff571f 5%,
          #ff8a5c 40%,
          #ff571f 60%,
          #ff8a5c 60%,
          #ff571f 95%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .biology {
        background: linear-gradient(
          to left,
          #0ac2a6 20%,
          #51f6f0 40%,
          #51f6f0 60%,
          #0ac2a6 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 8s linear both infinite;
        background-size: 200% auto;
      }
      .history {
        background: linear-gradient(
          to left,
          #999cff 20%,
          #99c3ff 40%,
          #99c3ff 60%,
          #999cff 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .civics {
        background: linear-gradient(
          to left,
          #85c445 20%,
          #c3db9f 40%,
          #c3db9f 60%,
          #85c445 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .geography {
        background: linear-gradient(
          to left,
          #ff7f5c 20%,
          #ffc5ad 40%,
          #ffc5ad 60%,
          #ff7f5c 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .economics {
        background: linear-gradient(
          to left,
          #efbd81 20%,
          #f6e8a2 40%,
          #f6e8a2 60%,
          #efbd81 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .literature {
        background: linear-gradient(
          to left,
          #ff4b33 20%,
          #f78978 40%,
          #f78978 60%,
          #ff4b33 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .writing {
        background: linear-gradient(
          to left,
          #00b899 20%,
          #58e0bb 40%,
          #58e0bb 60%,
          #00b899 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .grammar {
        background: linear-gradient(
          to left,
          #de85ff 20%,
          #f3c2ff 40%,
          #f3c2ff 60%,
          #de85ff 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
      .reading {
        background: linear-gradient(to left, #e9275b 80%, #f07f8c);
        background: linear-gradient(
          to left,
          #e9275b 20%,
          #f07f8c 40%,
          #f07f8c 60%,
          #e9275b 80%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        animation: flowingText 6s linear both infinite;
        background-size: 200% auto;
      }
    }

    &-image {
      position: relative;

      &-holder {
        img {
          @media screen and (max-width: 500px) {
            height: 118px;
            width: 100%;
          }
        }
      }

      &-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #2b3245;
        border-radius: 8px;
        //aspect-ratio: 16 / 9;
        object-fit: contain;
      }
    }

    &-bottom {
      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        &-image {
          position: relative;
          flex-shrink: 0;

          &-holder {
            img {
              @media screen and (max-width: 500px) {
                width: 20px;
                height: 20px;
              }
            }
          }

          &-item {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 75%;
            height: 75%;
            z-index: 2;
            transform: translate(-50%, -50%);
          }

          &-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            &.maths {
              background: linear-gradient(to top, #fb8b23, #ffdd00);
            }
            &.physics {
              background: linear-gradient(to top, #ed82c1, #f9c6d7);
            }
            &.chemistry {
              background: linear-gradient(to top, #ff571f, #ff8a5c);
            }
            &.biology {
              background: linear-gradient(to top, #0ac2a6, #51f6f0);
            }
            &.history {
              background: linear-gradient(to top, #999cff, #99c3ff);
            }
            &.civics {
              background: linear-gradient(to top, #85c445, #c3db9f);
            }
            &.geography {
              background: linear-gradient(to top, #ff7f5c, #ffc5ad);
            }
            &.economics {
              background: linear-gradient(to top, #efbd81, #f6e8a2);
            }
            &.literature {
              background: linear-gradient(to top, #ff4b33, #f78978);
              .sub-icon {
                transform: scaleX(-1) translate(50%, -50%);
              }
            }
            &.writing {
              background: linear-gradient(to top, #00b899, #58e0bb);
            }
            &.grammar {
              background: linear-gradient(to top, #de85ff, #f3c2ff);
            }
            &.reading {
              background: linear-gradient(to top, #e9275b, #f07f8c);
            }
          }
        }

        &-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 1.6;
          color: white;

          @media screen and (max-width: 500px) {
            font-size: 11px;
          }
        }
      }

      &-skill {
        //background-color: #660000;
        padding: 4px 8px;
        //color: #FFCFCF;
        border-radius: 10px;
        font-size: 11px;

        background: linear-gradient(to bottom, #ea0304, #a10e0e);
        color: white;
        font-weight: 600;
        //padding: 0.8rem 2rem;
        filter: brightness(0.95) saturate(1);
        transition: all ease 0.2s;
        box-shadow: 0 0 24px #a10e0e;

        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      &-hidden {
        max-height: 0;
        overflow: hidden;
        display: block;
        font-size: 12px;
        line-height: 1.6;
        color: #c2c8cc;
        transition: all 150ms ease-out !important;
        margin-top: 0;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}

.carousel-comment {
  &-ul {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-basis: auto;
    flex-shrink: 0;
    gap: 20px;
    transform: translateX(-100px);
    -webkit-box-align: center;
    height: 250px;

    @media screen and (max-width: 500px) {
      gap: 15px;
      height: 160px;
    }
  }

  &-list-item {
    @apply transition-all w-[369px] relative rounded-3xl p-px before:absolute before:w-96 before:h-96 before:-left-48 before:-top-48 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:hover:opacity-20 before:z-30 after:absolute after:inset-0 after:rounded-[inherit] after:opacity-0 after:transition-opacity after:duration-500 after:[background:_radial-gradient(250px_circle_at_var(--mouse-x)_var(--mouse-y),theme(colors.gray.400),transparent)] after:group-hover:opacity-100 after:z-10 overflow-hidden;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 8px 16px 0px rgba(2, 2, 3, 0.32);
      border-color: #3c445c;

      //.carousel-imaged-item-bottom-hidden {
      //  max-height: 116px;
      //  margin-top: 8px;
      //}
    }

    @media screen and (max-width: 500px) {
      //zoom: 0.8;
      gap: 11px;
      width: 220px;
      //background: none;
      //border: none;
      //box-shadow: none;
      border-radius: 10px;
      //padding: 10px;
    }
  }
}

.ul-holder {
  display: flex;
  flex-direction: column;
  gap: 27px;
  flex-shrink: 0;
  outline: none;
  flex-basis: auto;
  align-items: stretch;

  @media screen and (max-width: 500px) {
    gap: 27px;
  }

  &-item {
    flex-shrink: 0;
    min-height: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    -webkit-box-align: stretch;
    align-items: stretch;
  }
}

.title-text-landing-page {
  background-clip: text;
  background-size: 200% auto;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;

  &.home-title {
    background-image: var(--var-border-home-icon);
  }

  &.videos-title {
    background-image: var(--var-border-videos-icon);
  }

  &.news-title {
    background-image: var(--var-border-news-icon);
  }

  &.blaze-title {
    background-image: var(--var-border-blaze-icon);
  }
}

.home-bg {
  background: var(--home-radial);
}

.video-bg {
  background: var(--videos-radial);
}

.news-bg {
  background: var(--news-radial);
}

.blaze-bg {
  background: var(--blaze-radial);
}

.landing-mobile-content-card {
  border-radius: 6px;
  overflow: hidden;
  zoom: 1;
  @apply relative p-[3.5px] hover:scale-105 transition-all;

  @media screen and (max-width: 500px) {
    zoom: 0.85;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    opacity: 0;
  }

  &.safari {
    transform: none !important;
  }

  &.home11 {
    .bg11 {
      content: "";
      background-image: conic-gradient(#dd2476 20deg, transparent 0deg);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.videos11 {
    .bg11 {
      content: "";
      background-image: conic-gradient(#4c72fa 20deg, transparent 0deg);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.news11 {
    .bg11 {
      content: "";
      background-image: conic-gradient(#ff5f6d 20deg, transparent 0deg);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.blaze11 {
    .bg11 {
      content: "";
      background-image: conic-gradient(#4568dc 20deg, transparent 0deg);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.landing-mobile-content-card.active {
  .landing-mobile-content-card-content {
    box-shadow: 2px 0 28px 2px rgba(30, 4, 4, 0.27),
      -1px 1px 8px 2px rgba(30, 4, 4, 0.35);
  }
  @apply scale-105;

  @media screen and (max-width: 500px) {
    opacity: 1;
  }
}
.mobile-view-container {
  box-shadow: rgb(0, 0, 0) 0px -64px 64px 32px;
}

.iphone-container {
  @media screen and (max-width: 500px) {
    zoom: 0.75;
  }
}

.comment-para {
  @apply inline-flex font-medium bg-clip-text text-transparent bg-gradient-to-r from-[#8559f4] to-[#dcd6fe];
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .comment-para {
      background: none !important;
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.green-shadow-anim {
  //background: rgb(22, 173, 73);

  box-shadow: 0px 0px 0px 4px rgba(22, 173, 74, 0.75),
    0px 0px 0px 8px rgba(22, 173, 74, 0.5),
    0px 0px 0px 12px rgba(22, 173, 74, 0.25);

  animation: glowy2 1.8s infinite ease-out !important;

  @keyframes glowy2 {
    0% {
      box-shadow: 0px 0px 0px 3px rgba(22, 173, 74, 0.25),
        0px 0px 0px 6px rgba(22, 173, 74, 0.25),
        0px 0px 0px 4px rgba(22, 173, 74, 0.25);
    }

    33% {
      box-shadow: 0px 0px 0px 3px rgba(22, 173, 74, 0.5),
        0px 0px 0px 6px rgba(22, 173, 74, 0.25),
        0px 0px 0px 4px rgba(22, 173, 74, 0.25);
    }

    67% {
      box-shadow: 0px 0px 0px 3px rgba(22, 173, 74, 0.75),
        0px 0px 0px 6px rgba(22, 173, 74, 0.5),
        0px 0px 0px 4px rgba(22, 173, 74, 0.25);
    }

    100% {
      box-shadow: 0px 0px 0px 3px rgba(22, 173, 74, 0.25),
        0px 0px 0px 6px rgba(22, 173, 74, 0.25),
        0px 0px 0px 4px rgba(22, 173, 74, 0.25);
    }
  }
}

.comment__content {
  word-break: break-word;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped)
  .plyr__poster {
  display: block;
}

.badge-congratulations-title {
  //background: linear-gradient(270deg,#0638ee 5%, #1920d9 30%,#0638ee 50%, #0c5ff8 70%,#0638ee 95%);
  //color: transparent;
  //background-size: 200% auto;
  //background-clip: text;
  //text-fill-color: transparent;
  //-webkit-background-clip: text;
  //-webkit-text-fill-color: transparent;
  //animation: flowingText 8s linear infinite both;
  color: white;
}

.skills__modal {
  height: 100vh;
}

.orbit__1:hover .teacher__content,
.orbit__2:hover .teacher__content,
.orbit__3:hover .teacher__content {
  animation-play-state: paused !important;
}

.orbit__1:hover,
.orbit__2:hover,
.orbit__3:hover {
  animation-play-state: paused !important;
}

.call__to__action .start__learning__btn {
  cursor: pointer;
  z-index: 999;
  margin-top: 2rem;
  margin-bottom: 0rem;
  border-radius: 7.5px;
  background: linear-gradient(to bottom, #ea0304, #a10e0e);
  color: white;
  font-weight: 600;
  padding: 0.8rem 2rem;
  filter: brightness(0.95) saturate(1);
  transition: all ease 0.2s;
}
.call__to__action .start__learning__btn:hover {
  filter: brightness(1) saturate(1.05);
  transform: scale(1.05);
  transition: all ease 0.2s;
}

[data-placeholder]::before,
[align="left"][data-placeholder]::before {
  content: attr(data-placeholder);
  color: #d3d3d3;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: Campton, sans-serif;
  cursor: text;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

[align="center"][data-placeholder]::before {
  content: attr(data-placeholder);
  color: #d3d3d3;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Campton, sans-serif;
  cursor: text;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

[align="right"][data-placeholder]::before {
  content: attr(data-placeholder);
  color: #d3d3d3;
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
  font-family: Campton, sans-serif;
  cursor: text;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

li[data-placeholder]::before {
  left: 20px;
}

.breadcrumb-container::-webkit-scrollbar-track,
.breadcrumb-container::-webkit-scrollbar,
.breadcrumb-container::-webkit-scrollbar-thumb {
  height: 0;
  width: 0;
}

[data-slot="segment"]:before {
  content: "" !important;
}

.custom-date-picker > [data-slot="input-wrapper"] {
  border-radius: 0.3em 0.3em 0 0;
  border-bottom: 1px solid #6b7280;
}

.custom-date-picker [data-slot="segment"] {
  text-transform: uppercase;
}

.custom-date-picker-popover
  .data-\[selected\=true\]\:bg-primary[data-selected="true"] {
  --tw-bg-opacity: 0.3;
}

.sessions__cards__wrapper {
  position: absolute;
  top: 0;
  width: 100%;
}
